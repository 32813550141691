import { render, staticRenderFns } from "./AboutHistory.vue?vue&type=template&id=09bc023e&scoped=true&"
import script from "./AboutHistory.vue?vue&type=script&lang=js&"
export * from "./AboutHistory.vue?vue&type=script&lang=js&"
import style0 from "./AboutHistory.vue?vue&type=style&index=0&id=09bc023e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09bc023e",
  null
  
)

export default component.exports