<template>
  <div class="about">
    <AboutHeader />
    <AboutNavbar />
    <AboutIntroduce />
    <AboutValue />
    <AboutHistory />
    <AboutFounder />
    <AboutCulture />
  </div>
</template>

<script>
import AboutCulture from '@/components/AboutCulture.vue';
import AboutHeader from '@/components/AboutHeader.vue';
import AboutIntroduce from '@/components/AboutIntroduce.vue';
import AboutNavbar from '@/components/AboutNavbar.vue';
import AboutValue from '@/components/AboutValue.vue';
import AboutFounder from '@/components/AboutFounder.vue';
import AboutHistory from '../components/AboutHistory.vue';

export default {
  name: 'Home',
  components: {
    AboutCulture,
    AboutHeader,
    AboutIntroduce,
    AboutFounder,
    AboutNavbar,
    AboutValue,
    AboutHistory,
  },
};
</script>
