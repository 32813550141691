<template>
  <div id="history" class="history">
    <p class="swiper-container-p">发展历程</p>
    <img class="course-img" src="@images/about/licheng.jpg" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.history {
  position: relative;
  .course-img {
    max-width: 1100px;
    height: auto;
  }

  .swiper-container-p {
    padding-top: 60px;
    font-size: 38px;
    font-weight: 400;
    color: #333436;
    line-height: 53px;
  }

  .course-top-block {
    position: relative;
    display: flex;
    width: 920px;
    height: 2px;
    justify-content: space-around;
    margin: 0 auto;
    top: 55px;
    background: #aebee2;
  }
}
.mobile {
  .swiper-container-p {
    font-size: 24px;
    padding-top: 0;
    letter-spacing: 8px;
  }
  .course-img {
    width: 100%;
    padding: 0 10px;
  }
}
</style>
