<template>
  <div class="full-box navBar">
    <ul class="unfull-box navBar-content">
      <li data-to="introduction">公司简介</li>
      <li data-to="value">公司价值</li>
      <li data-to="history">发展历程</li>
      <li data-to="founder">创始合伙人</li>
      <li data-to="culture">企业文化</li>
    </ul>
  </div>
</template>

<script>
export default {
  mounted() {
    $('.navBar-content').on('click', 'li', function (e) {
      const location = $(e.target).data('to');
      const isMobile = $('#app').hasClass('mobile');
      if (isMobile) {
        $('html').animate(
          { scrollTop: $('.' + location).offset().top - (location === 'introduction' ? 100 : 120) },
          800
        );
      } else {
        $('html').animate({ scrollTop: $('.' + location).offset().top - (location === 'introduction' ? 60 : 80) }, 800);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.navBar {
  background-color: #f8f8f8;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 8;
}
.navBar-content {
  display: flex;
  justify-content: center;
  li {
    font-size: 16px;
    width: 80px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    margin-right: 40px;
    &:hover {
      color: #bd192f;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.mobile {
  .unfull-box {
    width: 100%;
  }
  .navBar {
    top: 50px;
  }
  .navBar-content {
    li {
      width: 20%;
      margin-right: 0;
      font-size: 14px;
    }
  }
}
</style>
