<template>
  <div id="value" class="value">
    <div class="value-left">
      <p>公司价值</p>
    </div>
    <div class="value-middle"></div>
    <div class="swiper-container-value">
      <swiper class="swiper-wrapper" :options="swiperOptions" ref="mySwiper">
        <swiper-slide class="value-right swiper-slide" v-for="(item, idx) in items" :key="idx">
          <div class="value-box">
            <p v-for="(desc, i) in item.desc" :key="'__' + i" class="value-right-p2">
              {{ desc }}
            </p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-value"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      items: [
        {
          desc: ['媒体价值放大', '股权未来可期', '不增加媒体企业成本', '只增加收入'],
        },
        {
          desc: ['轻松管理', '无需催收', '按时回款', '品牌多样化', '增加上刊率', '有利于拓客谈判'],
        },
      ],
      swiperOptions: {
        loop: true,
        noSwiping: true,
        autoplay: {
          delay: 3000, //可选选项，自动滑动
          disableOnInteraction: false,
        },
        slideThumbActiveClass: 'my-slide-thumb-active',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + ' "></div>';
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.value {
  width: 1200px;
  height: 330px;
  background: #f9f9f9;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
}
.value-left {
  p {
    margin: 0px 140px 0px 140px;
    font-size: 38px;
    font-weight: 400;
    color: #333436;
    line-height: 53px;
  }
}
.value-middle {
  width: 1px;
  height: 210px;
  background-color: #e0e0e0;
}
.swiper-container-value {
  position: relative;
  width: 753px;
  overflow: hidden;
  height: 100%;
}

.swiper-pagination-value {
  position: absolute !important;
  top: 100px !important;
  left: 100px !important;
  z-index: 99 !important;
  div {
    width: 8px;
    height: 8px;
    background: #0046e6 !important;
  }
  .swiper-pagination-bullet-active {
    background: #0046e6 !important;
  }
}
.value-right {
  padding-left: 100px;
  padding-right: 100px;
  text-align: left;
  font-weight: 400;
  &-p {
    font-size: 20px;
    color: #333436;
    line-height: 28px;
  }
  .value-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: left;
  }
  &-p2 {
    font-size: 24px;
    color: #bd192f;
    line-height: 1.8;
    font-weight: 500;
  }
}

.mobile {
  .value {
    width: 100%;
    position: relative;
    height: 280px;
    .value-left {
      width: 100px;
      p {
        font-size: 26px;
        margin: 0px 50px 0px 40px;
      }
    }
    .value-right {
      padding: 0 10px;
      &-p2 {
        font-size: 16px;
      }
    }
  }
}
</style>
