<template>
  <div id="culture" class="culture">
    <p>企业文化</p>
    <img src="@images/about/culture.jpg" alt="企业文化" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.culture {
  padding: 30px 0 100px;
  p {
    margin-bottom: 40px;
    font-size: 38px;
    font-weight: 400;
    color: #333436;
    line-height: 53px;
  }
  img {
    margin: 0 auto;
    display: block;
  }
}
.mobile {
  .culture {
    padding: 0 0 30px;
    p {
      font-size: 24px;
      margin-bottom: 20px;
      line-height: 1.2;
      letter-spacing: 8px;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
