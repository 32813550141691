<template>
  <div id="founder" class="founder">
    <p class="founder-p">创始合伙人</p>
    <div class="founder-box">
      <div class="founder-about" v-for="(team, idx) in teamList" :key="idx">
        <img :src="team.avatar" :alt="team.name" />
        <p class="name">{{ team.name }}</p>
        <p class="position">{{ team.title }}</p>
        <div>
          <p v-for="(intr, i) in splitIntro(team.intro)" :key="'___' + i">{{ intr }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teamList: [],
    };
  },
  methods: {
    splitIntro(intro) {
      if (!intro) return [];
      return intro.split(/[\\;\\；]/);
    },
  },
  async mounted() {
    let res = await this.$api.teamQueryTeamList({});
    (res || []).sort((a, b) => b.seqNo - a.seqNo);
    this.teamList = res;
  },
};
</script>

<style scoped lang="scss">
.founder {
  width: 1200px;
  margin: 20px auto 100px;
  text-align: center;
}
.founder-about {
  width: 380px;
  text-align: center;
  padding: 20px;
  &:hover {
    box-shadow: 0px 2px 20px 0px rgba(0, 70, 230, 0.1);
  }
  & > img {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
  }
  .name {
    margin-top: 32px;
    font-size: 28px;
    font-weight: 400;
    color: #bd192f;
    line-height: 40px;
  }
  .position {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #333436;
    line-height: 22px;
  }

  div {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #62656a;
    line-height: 24px;
  }
}
.founder-p {
  margin-bottom: 44px;
  font-size: 38px;
  font-weight: 400;
  color: #333436;
  line-height: 53px;
}
.founder-box {
  display: flex;
  justify-content: space-around;
}

.mobile {
  .founder {
    width: 100%;
    margin: 20px auto 36px;
    &-p {
      font-size: 24px;
      margin-bottom: 20px;
      letter-spacing: 8px;
    }
    &-box {
      overflow-x: scroll;
      justify-content: flex-start;
    }
    &-about {
      margin: 20px 14px;
      width: auto;
      padding-top: 20px;
      box-shadow: 0px 2px 20px 0px rgba(0, 70, 230, 0.1);
    }
  }
}
</style>
